* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  .welcome-header2 {
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 20px;
    color: #162082;
    text-align: center;
  }
  
  

  .welcome-log2-container {
      display: flex;
      width: fit-content;
      flex-direction: column;
      margin: 0 auto;
      background: rgba(255, 255, 255, 0.2);
      border-radius: 10px;
  }
  
  .welcome-login2-container {
    width: 531px;
    margin: 17px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    background-color: #fff;
  }
  
  .welcome-login2-contents {
    width: 90%;
    margin: 10% 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
    
  .welcome-email-section2 {
    display: flex;
    position: relative;
    margin: 0 30%;
    bottom: 50px; 
  }

  .username {
    justify-content: center;
    text-align: center;
    width: 35%;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;    
    color: #162082;
  }

  .btn-primary-1 {
    background: #162082;
    padding: 2% 12%;
    border-radius: 10px;
    color: #FFFFFF;
    border: none; 
    text-decoration: none;
    font-weight: 500;
    font-size: 22px;
    line-height: 26px;
    cursor: pointer;
    margin: 47px 0 0px 0;
    transition: 0.8s;
    transition-timing-function: ease-in-out;
  }

  .btn-primary-1:hover {
    background: cyan;
  }
