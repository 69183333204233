/* table {
    font-family: arial, sans-serif;
    border-collapse: separate;
    border-spacing: 0 15px;
    width: 100%;
  }
  td{
    font-weight: 400;
    font-size: 18px;
    text-align: center;
    height: 60px;
    color: #162082;
  }
   th {
    text-align: center;
    padding: 8px;
    color: #162082;
  }
  .table-content{
      width: 1196px;
      margin: auto;
  }
  .table-view-btn{
    
    background: #162082;
    border-radius: 5px;
    border: none;
    color: white;
    padding: 10px 16px;

  }

  .bill-cont {
    width: 70%;
    height: 100px;
    margin: 10px auto;
}

/* .vendor-table {
    width: 0% !important;
    height: 0% !important;
}

.vendor-compts {
    width: 0% !important;
    height: 0% !important;
} */

  /* .closecase {
    width: 80%;
    margin: 60px auto 0 auto;
    position: relative;
} */

  .modal-container {
    width: 100vw;
    height: 100vh;
    padding-top: 25px;
  }

  .modal-content {
    width: fit-content;
    background: white;
    margin: 0 auto;
    padding: 25px 35px;
    height: 820px;
    z-index: 2 !important;
  }

 .item-tr {
    border-radius: 218px !important;
    background: #dddff6;
 } 
  
td:first-child,
th:first-child {
  border-radius: 5px 0 0 5px;
}

/* Set border-radius on the top-right and bottom-right of the last table data on the table row */
td:last-child,
th:last-child {
  border-radius: 0 5px 5px 0;
}

.input-cont {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.input-cont button {
    width: 201px;
    height: 46px;
    margin: 0px 10px;
    background: #162082;
    color: #FFFFFF;
    outline: none;
    border: none;
    border-radius: 10px;
    font-size: larger;
    font-weight: 400;
}

.verify-pass {
    width: 39%;
    color: #6A616F;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    float: right
}

.search-section1 {
    width: 700px;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 20px;
    padding: 20px 6px;
    width: 100%;
}

.billcont h2 {
    margin-top: -70px;
    margin-bottom: 10px;
}

.table-container {
    width: auto;
}
.vtable-title {
    justify-content: space-around;
    align-items: center;
    margin: 0 300px;
    width: 100%;
}

/* .table {
    width: 100%;
    background: red;
    padding: 40px;
} */

.vtable-title  div{
    /* background: white; */
    display: flex;
    flex-direction: row;
}

.verify-tablelist td {
    background: white;
}

.verify-address-head h2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #162082;
}

.cancel-icon4 {
    float: right;
}

.verify-list1 {
    padding: 10px;
}

.verify-btn {
    width: 80px !important;
    border-radius: 4px !important;
}

.vtable-wrapper1 {
    width: 100%;
    height: 200px;
    padding: 20px;
}

.vtable-title1 {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 160px;
    padding-right: 100px;
    background-color: #ffffff;
}

.verify-btn {
    width: 80px !important;
    border-radius: 4px !important;
}