@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
*{
  padding: 0;
  margin: 0;
  font-family: 'Rubik', sans-serif;
}

/* .dashboard-nav{
    position: fixed;
} */
.dashboard-container{
    display: flex;
    width: 100vw;
}
.dashboard-user-preloader {
    width: 100vw;
    height: 100vh;
    align-items: center;
    position: fixed;
    left: 0;
    top: 0px;
    background: rgba(0, 0, 0, 0.10);
    overflow: hidden;
    z-index: 1;
}
.dashboard-nav{

   z-index: 100000;
}
 .dashboard-content-wrapper{
  /* grid-area: wrapper;   */
  width: 100%;
  height: 100vh;
  /* overflow-y: scroll; */
} 
.dashboard-body-wrapper {
    width: 100%;
    height: 87vh;
    overflow-y: scroll;
}
.dashboard-content{
    display: flex;
    justify-content: space-between;
    margin: 10px;
}
.kyc-dist-cash-provider{
    display: flex; 
    justify-content: space-between;
    margin: 20px 10px;
}
.kyc-issue{
    width: 43%;
}
.kyc-issue div{
    margin-bottom: 25px;
}
.dashboard-card-color{
    background: #F8F7F7;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}
.dashboard{
    width: 100vw;
    height: 100vh;
    padding: 100px;
    background: linear-gradient(45deg, rgba(253, 97, 6, 0.8), rgba(253, 97, 6, 0) 40.71%),
    linear-gradient(135deg, rgba(0,0,255,.8), rgba(0,0,255,0) 70.52%),
    linear-gradient(200deg, rgba(0, 40, 135, 0.8) , rgba(0, 40, 135, 0) 70.52%),
    linear-gradient(310.7deg, rgba(73, 199, 0, 0.8) , rgba(73, 199, 0, 0) 70.52%);
}
.dashboard-reset{
    width: 100vw;
    height: 100vh;
    padding: 30px;
    background: linear-gradient(45deg, rgba(253, 97, 6, 0.8), rgba(253, 97, 6, 0) 40.71%),
    linear-gradient(135deg, rgba(0,0,255,.8), rgba(0,0,255,0) 70.52%),
    linear-gradient(200deg, rgba(0, 40, 135, 0.8) , rgba(0, 40, 135, 0) 70.52%),
    linear-gradient(310.7deg, rgba(73, 199, 0, 0.8) , rgba(73, 199, 0, 0) 70.52%);
}