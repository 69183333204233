.vendor-container{
    display: flex;
}
.vendor-wrap{
    width: 100%;
}

.vendor-compts{
    width: 100%;
    height: 100%;
}
.vendor-table-main{
    width: 90%;
    border-spacing: 0 5px;
    border-collapse: separate;
}
.vendor-table-main td {
    text-align: left;
    padding-left: 15px;  
}
.vendor-table-main th {
    text-align: left;
    padding-left: 15px;
}
.vendor-table {
    width: 100%;
    height: 50%;
    margin-top: 60px;
}
.vendor-dash-card {
    /* width: 80%; */
    height: 130px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.vendor-vcard {
    width: 357px;
    height: 94px;
    border-radius: 10.4386px;
    border: 1.04386px solid #462C94;
    background: #FFFFFF;
    box-shadow: 0px 12.5263px 29.2281px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    margin-left: 100px;
}
.vcard {

    width: 357px;
    height: 94px;
    border-radius: 10.4386px;
    border: 1.04386px solid #462C94;
    background: #FFFFFF;
    box-shadow: 0px 12.5263px 29.2281px rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
}
.vcard p{
    width: 200px;
}
.vcard p:hover{
    font-weight: bold;
}
.vcard:hover {
    background-color: #DDDFF7;
    box-shadow: 0px 8.20513px 19.1453px #A8D5F2;
}
.active {
    background-color: #DDDFF7;
    box-shadow: 0px 8.20513px 19.1453px #A8D5F2;
}
.bb{
    font-weight: bold;
}
.search-section {
    width: 700px;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 20px;
    padding: 20px 6px;
    width: 100%;
}
.search-section2 {
    width: 95%;
    height: 80px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-left: 20px;
    padding: 20px 6px;
    /* width: 100%; */
    padding-right: 30px;
    
}
.kscont {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
    width: 90%;
    height: 40px;
    margin-right: 50px;
}
.kscont img{
    margin-right: 8px;
}
.search-section h2 {
    color: #162082;
    font-size: 24px;
    font-weight: 600;
}
.inputcont {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.inputcont input {
    width: 474px;
    height: 65px;
    background: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 15px;
}
.inputcont button {
    width: 171px;
    height: 46px;
    background: #162082;
    color: #FFFFFF;
    outline: none;
    border: none;
    border-radius: 10px;
    font-size: larger;
    font-weight: 500;
}
.vendor-table-content {
    width: 95%;
}
.vtable-wrapper {
    width: 95%;
    height: 200px;
    padding: 20px;
    margin-top: -40px;
    margin-left: 70px;
}
.vtable-titlee {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    padding-left: 160px;
    padding-right: 100px;
    background-color: #831111;
}
.vtable-titlee2 {
    display: flex;
    width: 85%;
    justify-content: space-between;
    align-items: center;
    padding-left: 80px;
    background-color: #FFFFFF;
}
.l1 {
    margin-left: 100px;
}
.vtable-titlee button {
    width: 73px;
    height: 33px;
    background-color: #162082;
    border: none;
    outline: none;
    border-radius: 10px;
    color: white;
    font-weight: 700;
}
.vtable-titlee2 button {
    width: 73px;
    height: 33px;
    background-color: #162082;
    border: none;
    outline: none;
    border-radius: 10px;
    color: white;
    font-weight: 700;
}
.vtablelist {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0 22px;
    align-items: center;
}
.vtablelist button {
     width: 33px;
    height: 33px;
    background-color: #162082;
    border: none;
    outline: none;
    border-radius: 100%;
    color: white;
    font-weight: 700;
    cursor: pointer;
}

.vtable-btn {
    width: 33px;
   height: 33px;
   background-color: #162082;
   border: none;
   outline: none;
   border-radius: 100%;
   color: white;
   font-weight: 700;
   cursor: pointer;
}

.oneside {
    display: flex;
    justify-content: space-between;
    width: 300px;
}
.oneside p{
    width: 150px;
}
.green{
    color: green;
}
.red {
    color: red;
}