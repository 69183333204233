.main-chat {
  width: 500px;
  height: 710px;
  background-color: #fff;
  /* position: absolute;
  top: 0;
  right: 350px; */
  
}
.chat-body {
  padding: 10px 20px;
  height: 520px;
  overflow-y: scroll;
}
.top-bar {
  padding: 20px;
  display: flex;
  justify-content: flex-end;
}
.second {
 margin: auto 10px;
 display: flex;
}
.first { 
  width: 92px;
  height: 35px;
  margin: auto 20px;
  background: #F3F3F3;
  border-radius: 5px;
  padding: 10px;
  display: flex;

  font-family: 'Rubik';
  font-weight: 400;
  font-size: 15px;
}
.divider {
  border: 1px solid rgba(0, 0, 0, 0.15);
  margin: 0px 20px;
}
.day {
  float: left;
  width: 100%;
  margin: 15px 0px;
  display: flex;
  justify-content: space-between;
}
.day-left {
  margin-top: 10px;
  width: 40%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: 0px;
}
.day-right {
  margin-top: 10px;
  width: 40%;
  border: 1px solid rgba(0, 0, 0, 0.15);
  height: 0px;
}

.body {
  margin: 20px auto;
}
.body-right {
  width: 90%;
  height: auto;
  background-color: #162082;
  border-radius: 5px;
  padding: 10px;
  color: #ffffff;
  margin-bottom: 10px;
}
.image {
  width: 0%;
}
.right {
  float: right;
  width: 60%;
  height: auto;
  display: flex;
  justify-content: flex-end;
}
.body-left {
  float: left;
  width: 50%;
  height: auto;
  background-color: #F3F3F3;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 12px;
  margin-left: 5px;
}
.chat-bottom {
  display: flex;
  margin: 35px 10px;
}
.input-container {
  width: 90%;
  height: 100%;
}
.input-container input {
  width: 100%;
  height: auto;
  border-radius: 15px;
  background-color: #F3F3F3;
  color: black;
  font-size: 15px;
  padding: 10px;
}