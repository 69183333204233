.line-chart-section{
    padding: 20px 0px 0 0px;
    border-radius: 15px 0px 0px 0px;
}
#my-chart {
    --color: linear-gradient(180deg, #9FA6E7 100%, #162082 0%);
    opacity: 0.8;
    /* height: 170px; */
}
#my-chart span {
    width: 150px;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 12.9396px;
}
.line-chart-title-section{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1.37118px solid #162082;
    margin: 0px 17px 0px 17px;
}
.line-chart-title-section select{
    width: 130px;
    height: 42px;
    background: #7780De;
    color: #162082;
    font-size: 17px;
    font-weight: 600;
    padding:10px 19px;
    border-radius: 20.5677px;
    outline: none;
}
.line-chart-title-section p{
    font-weight: 700;
    font-size: 20.6812px;
    line-height: 30px;
    width: fit-content;
}
.line-chart-details{
    display: flex;
    justify-content: space-between;
    margin-top: 48px;
    padding-right: 12%;
    padding-left: 4%;
    margin-bottom: 5px;
}

.chart-details{
    margin-right: 70px;
}

.line-chart-details p{
    width: fit-content;
    color: #162082;
    font-weight: 400;
    font-size: 17.8253px;
    margin-bottom: 6.79px;
}
.line-chart-details h1{
    margin-bottom: 17.35px;
    color: #162082;
}
.line-chart-section h5{
    font-weight: 600;
    color: #162082;
    margin-left: 4%;
    font-size: 15px;

}

.rm-link-dec{
    text-decoration: none;
}
.line-chart-area{
    height: 200px;
    display: flex;
    align-items: center;
    align-self: center;
    color: wheat;
}
