header {
    grid-area: header;
    background-color: #fed330;
    padding: 20px;
  }
  .content {
    grid-area: content;
    
  }
  .content-cont {
      width: 90%;
      background-color: rgb(245, 111, 111);
      height: 590px;
      margin: 0 auto;
  }
  .card-container {
    width: 90%;
    height: 160px;
    background-color: rgb(19, 18, 18);
    margin: 0 auto;
}
.table-container {
    width: 95%;
    height: 360px;
    background-color: rgb(213, 202, 202);
    margin: 0 auto;
    overflow-y: scroll;
    padding: 0 30px;
}
.main-table {
    width: 100%;
    height: 800px;
    background-color: #1a754c;
}
  aside {
    grid-area: sidebar;
    background-color: #45aaf2;
    height: 100%;
    padding: 10px;
  }
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 5fr;
    grid-template-areas: 
      "sidebar content"
  }
  
  @media (max-width: 500px) {
    .wrapper {
      grid-template-columns: 4fr;
      grid-template-areas:
        "header"
        "content"
        "sidebar"
        "footer";
    }
  }



.Layout2-container{
    display: flex;
    width: 100vw;
    
}
.Layout2-nav{
  position: fixed;
  top: 0;
  bottom: 0;
  overflow: auto;
}
 .Layout2-content-wrapper{

  margin-left: 246px;
} 
.Layout2-content{
    display: flex;
    justify-content: space-between;
    
    margin: 10px;
}
.kyc-dist-cash-provider{
    display: flex; 
    justify-content: space-between;
    margin: 20px 10px;
}
.kyc-issue{
    width: 43%;
}
.kyc-issue div{
    margin-bottom: 25px;
}
.Layout2-card-color{
    background: #F8F7F7;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}

  .nav-layer-1 {
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to bottom, #99ccff 0%, #ffcc99 100%);
      padding: 10px;
  }
  .nav-layer-2 { 
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.curved-up {
    height: 35%;
    width: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 7.5px 7.5px 100px 100px;
}
.curved-up-name {
    width: fit-content;
    margin-bottom: 14px;
    text-align: center !important;
}
.curved-up img {
    width: 45%;
}
.curved-down {
    height: 60%;
    width: 100%;
    background-color: white;
}
