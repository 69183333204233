.app-notification {
  display: flex;
}
.embed-link-class:focus{
  outline: none;
  
}
.embed-link-class {
  padding: 15px 0px 15px 10px;
  border: none;
}

.app-notification-content {
  height: 100vh;
  overflow: auto;
}
.notification-title {
  font-size: 17px;
}
.notification-time {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.time-section .column {
    width: 45px;
}
.time-section {
  display: flex;
  flex-direction: column;
}
.time-arrow {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.time-box {
  background: #ffffff;
  border: 2px solid rgba(106, 97, 111, 0.2);
  box-sizing: border-box;
  width: 45px;
  height: 25px;
  text-align: center;
}
.notification-title-text-input {
  margin-top: 40px;
}
.notification-title-text-input h4 {
  color: #6a616f;
  font-size: 14px;
}
.notification-title-text-input input[type="text"] {
  width: 350px;
  border: 1px solid rgba(106, 97, 111, 0.2);
  border-radius: 10px;
  height: 46px;
  padding-left: 15px;
}
.notification-textArea {
  margin-top: 40px;
}
.notification-textArea textarea {
  width: 355px;
  height: 125px;
  border: 1px solid rgba(106, 97, 111, 0.2);
  border-radius: 10px;
  padding: 10px;
}
.notification-image-section {
  display: flex;
}
.notification-radio-divs {
  display: flex;
}
.notification-radio-divs div:first-child {
  margin-right: 50px;
}
.notification-radio-divs div input {
  margin-right: 10px;
}

.notification-image-section h5,
.notification-date-section h5,
.notification-radio-section h5 {
  font-size: 12px;
  margin-top: 40px;
  color: #6a616f;
}
.notification-content-section {
  margin-top: 20px;
  margin-left: 20px;
}
.image-section-div {
  display: flex;
  height: 56px;
  width: 225px;
  align-items: center;
  border: 1px solid rgba(106, 97, 111, 0.2);
  font-size: 14px;
  border-radius: 10px;
  padding: 10px;
  cursor: pointer;
}
.notification-img-txt {
  margin-left: 10px;
}
.select-section {
  margin-left: 50px;
}
.select-option {
  height: 56px;
  width: 225px;
  border: 1px solid rgba(106, 97, 111, 0.2);
  border-radius: 10px;
  padding: 10px;
}
.notification-date-section {
  display: flex;
}
.notification-date {
  margin-left: 0px;
}
.notify-date-section {
  height: 56px;
  width: 225px;
  border: 1px solid rgba(106, 97, 111, 0.2);
  padding: 13px 0 10px 0px;
  border-radius: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  cursor: pointer;
}
/* .notify-date-section input[type=date]{
    border: none;
    font-size: 14px;

} */
.notification-footer-2 {
  margin-top: 10px;
  display: flex;
  width: 272px;
  height: 46px;
  border: 1px solid rgba(106, 97, 111, 0.2);
  border-radius: 10px;
  padding: 12px;
  cursor: pointer;
}
.notification-footer-3 {
  margin-top: 20px;
  margin-bottom: 30px;
}
.notification-footer-3 button {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
}
.notification-footer-botton {
  margin-right: 20px;
  width: 200px;
  height: 56px;
  background: #162082;
  border-radius: 10px;
  color: #ffffff;
  border: none;
}

.notification-footer-close {
  height: 56px;
  background-color: white;
  border: none;
  color: #162082;
}
