.bank-card-container{
    position: fixed;
    height: 100vh;
    width: 100%;
    background-color: rgba(0,0,0,0.2);
    overflow: hidden;
    z-index: 1500;
    display: flex;
    justify-content: center;
    align-items: center;
}
.close {
    display: none;
}
.bank-card-cancel{
    float: right;
    cursor: pointer;
}
.bank-card-title{
    margin: 0 auto;
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;

    color: #462C94;
    margin-bottom: 56px;
}
.bank-card-content{
    background: white;
    width: 676px;
    padding: 25px 24px 50px 28px;
    margin: auto;
    border-radius: 5px;
}
.bank-card-table{
    width: 100% !important;
}
.bank-card-table th{
    font-family: 'Rubik';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    color: #162082;
}
.bank-card-btn button{
    background: #FC3131;
border-radius: 5px;
width: 75px;
height: 35px;
color: white;
border: none;
cursor: pointer;

}
.bank-section{
    margin-bottom: 30px;
}
.bank-section h5,.card-section h5{
    font-family: 'Rubik';
font-style: normal;
font-weight: 700;
font-size: 18px;
margin-bottom: 10px;
/* identical to box height, or 0px */



color: #6A616F;
}