.bills-vendor-details-container {
    position: relative;
    background: #fff;
    width: 678px;
    height: 787px;
    margin: 10px auto;
    border-radius: 20px;
}

.bills-vendor-details {
    align-items: center;
    margin-left: 0px;
    margin-top: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #462C94;
}

.cancel-icon5 {
    margin-left: 630px;
    align-items: center;
    margin-top: -20px;
    cursor: pointer;
}

.bills-details-content {
    margin-bottom: 20px;
}

.bills-details-header {
    display: flex;
    justify-content: center;
}

.bills-vendor-details-contents {
    display: flex;
    justify-content: space-between;
    margin: 40px 170px;
}

.bills-details-content h5 {
    font-weight: 400;
    color: #462C94;
}

.bills-details-content p{
    width: 100%;
    color: #6A616F;
}

.bills-close-details {
    display: none;
}

.bills-service {
    margin-top: 10px;
}

.bills-service div{
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    background: #E4E3E3;
    display: inline-block;
    padding: 13px 20px;
    margin: 6px 3px;
    border-radius: 40%;
    
}
